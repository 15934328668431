
import { Options, Vue } from "vue-class-component";

@Options({
  component: {components: {}},
  mixins: []
})
export default class NoFind extends Vue {

  
  clickBack() {
    (<any>this).$router.back();
  }

  clickHome() {
    (<any>this).$router.replace("/");
  }
}
